import * as React from "react"
import { memo } from "react"
import colorConfigs from "../../configs/colorConfigs"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={267}
        height={79}
        preserveAspectRatio="xMidYMid"
        style={{
            display: "block",
            shapeRendering: "auto",
            background: "0 0",
        }}
        {...props}
    >
        <style>
            {
                "@keyframes animate-r50lcehtfpr{0%{transform:translate(0,0) rotate(0deg) scale(.91,.91) skew(0deg,0deg);opacity:1}4%{transform:translate(0,0) rotate(0deg) scale(.91,.91)}8%{animation-timing-function:cubic-bezier(.69,.6,.35,.27);transform:translate(0,0) rotate(0deg) scale(.91,.91)}14%{transform:translate(0,0) rotate(0deg) scale(.93,.93)}18%{transform:translate(0,0) rotate(0deg) scale(.94,.94)}22%{animation-timing-function:cubic-bezier(.67,.66,.34,.33);transform:translate(0,0) rotate(0deg) scale(.96,.96)}26%{transform:translate(0,0) rotate(0deg) scale(.97,.97)}30%{transform:translate(0,0) rotate(0deg) scale(.99,.99)}34%{animation-timing-function:cubic-bezier(.65,.71,.32,.38);transform:translate(0,0) rotate(0deg) scale(1.01,1.01)}40%{animation-timing-function:cubic-bezier(.64,.74,.31,.41);transform:translate(0,0) rotate(0deg) scale(1.02,1.02)}46%{animation-timing-function:cubic-bezier(.6,.91,.23,.63);transform:translate(0,0) rotate(0deg) scale(1.03,1.03)}50%,54%{transform:translate(0,0) rotate(0deg) scale(1.03,1.03)}58%{animation-timing-function:cubic-bezier(.69,.6,.35,.27);transform:translate(0,0) rotate(0deg) scale(1.03,1.03)}64%{transform:translate(0,0) rotate(0deg) scale(1.01,1.01)}68%{transform:translate(0,0) rotate(0deg) scale(1,1)}72%{animation-timing-function:cubic-bezier(.67,.66,.34,.33);transform:translate(0,0) rotate(0deg) scale(.98,.98)}76%{animation-timing-function:cubic-bezier(.66,.68,.33,.35);transform:translate(0,0) rotate(0deg) scale(.97,.97)}82%{animation-timing-function:cubic-bezier(.65,.71,.32,.38);transform:translate(0,0) rotate(0deg) scale(.94,.94)}88%{animation-timing-function:cubic-bezier(.65,.73,.31,.4);transform:translate(0,0) rotate(0deg) scale(.92,.92)}94%,to{animation-timing-function:cubic-bezier(.63,.8,.28,.48);transform:translate(0,0) rotate(0deg) scale(.91,.91)}}"
            }
        </style>
        <g
            className="ld-text"
            style={{
                transformOrigin: "133.5px 39.5px",
                transform: "scale(1)",
            }}
        >
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "-130.2px -10.57px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.588235s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="m4.64-19.14 10.96-2-1.04 41.12.88 18.8-10.8 2 .96-19.44-.96-40.48"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "-100.8px .23px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.529412s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="M22.48 24.06q0-10.24 5.52-17.36Q33.52-.42 40.92-.42t11.52 4.8q4.12 4.8 4.12 12.72 0 10.56-5.32 17.56-5.32 7-13.4 7-6.64 0-11-5t-4.36-12.6zm25.12-2.8q0-11.6-7.92-11.6-8.24 0-8.24 10.88 0 5.68 2.32 8.88 2.32 3.2 6.12 3.2 3.8 0 5.76-2.92t1.96-8.44"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "-62.96px -.09px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.470588s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="M71.44 40.78q-4.72 0-7.72-3t-3-8.08q0-5.08 4.64-8.8 4.64-3.72 11.28-3.72h5.44v-1.36q0-3.92-1.6-5.36-1.6-1.44-6-1.44-1.84 0-4.36.76-2.52.76-5.64 2.36l-2.32-6.32q3.44-2.4 8.36-4.32 4.92-1.92 8.12-1.92 12.56 0 12.56 12.16v15.68q0 4.48 2.8 10l-8.48 3.6q-2-3.84-2.96-6.88-4.4 6.64-11.12 6.64zm3.36-8.16q3.2 0 7.28-3.28v-5.36q-4.24-.96-6.88-.96-5.04 0-5.04 4.48 0 2.32 1.28 3.72 1.28 1.4 3.36 1.4"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "-24.48px -10.13px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.411765s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="M122.08 32.94q-6.24 7.84-10.8 7.84-5.68 0-9.32-4.64-3.64-4.64-3.64-11.92 0-10 5.56-16.88Q109.44.46 117.52.46l4.48.64V-.42l-.96-18.72 10.96-2-1.04 41.12v7.04q0 4.8 2.4 11.04l-8.64 3.6q-2.32-4.8-2.64-8.72zm-14.8-12.88q0 5.12 1.88 8t5.44 2.88q3.56 0 7.4-2.88V10.78q-4.8-.96-7.12-.96-3.6 0-5.6 2.56-2 2.56-2 7.68"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "6.12px .03px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.352941s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="m141.12 2.06 10.72-2-.96 19.92.88 18.8-10.72 2 .96-19.44-.88-19.28"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "6.48px -32.41px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.294118s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="M140.56-11.66q0-2.68 2.08-4.8 2.08-2.12 4.8-2.12 2.72 0 4.16 1.48t1.44 4.44q0 2.96-2.04 5.08t-4.72 2.12q-2.68 0-4.2-1.76t-1.52-4.44"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "36.4px .23px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.235294s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="M183.84 20.54q0-5.52-.96-7.36-.96-1.84-4.48-1.84-3.52 0-8 2.8v5.84l.88 18.8-10.72 2 .96-19.44q-.32-10.72-1.76-19.2l9.68-2.08q.56 4.64.8 9.04 1.2-1.6 2.72-3.16 1.52-1.56 4.8-3.52t6.92-1.96q3.64 0 6.12 2.84t2.48 7.72l-.48 8.96.88 19.2-10.8 2 .96-20.64"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "78.96px 8.59px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.176471s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="M199.68 48.7q0-7.04 13.12-11.04-3.12-1.6-3.12-4.16 0-1.04.8-2.08t2.32-2.16v-.64q-5.76 0-9.16-3.2-3.4-3.2-3.4-8.56 0-6.96 5.68-12.12 5.68-5.16 13.44-5.16 2 0 5.84.8t7.52.8h6.16l-.8 6.96-6-.72q.8 2.32.8 5.28 0 2.96-1.4 5.88t-3.36 4.68q-1.96 1.76-3.92 3.08t-3.36 2.32q-1.4 1-1.4 1.8 0 1.52 3.2 2.64 8.48 3.44 11.48 5.84t3 5.92q0 5.92-5.96 9.72-5.96 3.8-14.2 3.8-8.24 0-12.76-2.64t-4.52-7.04zm24.16-33.6q0-3.92-1.76-5.76-1.76-1.84-5.52-1.84-7.28 0-7.28 7.16t7.76 7.16q6.8 0 6.8-6.72zm2.88 30.96q0-1.44-1.52-2.68-1.52-1.24-5.44-2.92-5.84 1.6-8.04 2.96-2.2 1.36-2.2 3.36 0 2 2.2 3.24 2.2 1.24 6.12 1.24t6.4-1.44q2.48-1.44 2.48-3.76"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "106.36px 14.71px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.117647s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="M240.88 35.42q0-2.56 1.88-4.52 1.88-1.96 4.52-1.96 5.2 0 5.2 5.28 0 3.04-1.88 5.04t-4.4 2q-2.52 0-3.92-1.64t-1.4-4.2"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "122.76px 14.71px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear -.0588235s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="M257.28 35.42q0-2.56 1.88-4.52 1.88-1.96 4.52-1.96 5.2 0 5.2 5.28 0 3.04-1.88 5.04t-4.4 2q-2.52 0-3.92-1.64t-1.4-4.2"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
            <g
                style={{
                    opacity: 1,
                    transformOrigin: "139.16px 14.71px",
                    transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                    animation:
                        ".980392s linear 0s infinite normal forwards running animate-r50lcehtfpr",
                    transformBox: "view-box",
                }}
            >
                <path
                    d="M273.68 35.42q0-2.56 1.88-4.52 1.88-1.96 4.52-1.96 5.2 0 5.2 5.28 0 3.04-1.88 5.04t-4.4 2q-2.52 0-3.92-1.64t-1.4-4.2"
                    className="path"
                    style={{
                        fill: colorConfigs?.sidebar?.bg,
                    }}
                    transform="matrix(.825 0 0 .825 17.736 22.68)"
                />
            </g>
        </g>
    </svg>
)
const Loading = memo(SvgComponent)
export default Loading
