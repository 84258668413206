import React from 'react'
import Loading from '../../assets/svg/Loading'
import logoo from "../../assets/dashboard.png";
import logowhite from "../../assets/womens-first/logowhite.png";
import { Box } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
function LoadingPage() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', gap: 4 }}>
            <img data-no-gallery={true} alt="logo" style={{ height: 280, width: 280, margin: '10px auto', display: 'block', borderRadius: '50%', background: colorConfigs?.sidebar?.bg }} src={process.env.REACT_APP_ENV === 'women_first' ? logowhite : logoo} />
            <Loading />
        </Box>
    )
}

export default LoadingPage